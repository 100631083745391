'use client'
import { IGif } from '@giphy/js-types'
import { getGifHeight } from '@giphy/js-util'
import { Gif as _Gif, Video } from '@giphy/react-components'
import { useContext, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import styled from 'styled-components'
import { GifsContext } from '../(site)/gifs/gifs-context'
import GifNav from '../(site)/gifs/[id]/related-gif-nav'

const Gif = styled(_Gif)`
    img {
        object-fit: contain;
    }
    transition:
        height 100ms ease-out,
        width 100ms ease-out;
`

type Props = { gif: IGif; isVideo?: boolean }

// 300 is a guess for the server side rendition for mobile
const mobileSSRWidth = 350
const targetHeight = 500

/**
 * Display a gif with a percentage width on the server and a defined width on the client.
 * We use a defined width on the client so we can transition the width and height.
 */
const ResponsiveGif = ({ gif: serverGif, isVideo }: Props) => {
    const { currentGif: lastRenderedGif, setCurrentGif } = useContext(GifsContext)
    // render with the last gif's width and height if we've navigated to a new gif
    const gif = lastRenderedGif || serverGif
    // will be used for client transtions
    const { width: windowWidth } = useWindowSize()
    useEffect(() => {
        // update the current gif so we use the new width and height
        setCurrentGif(serverGif)
    }, [serverGif, setCurrentGif])
    // first render - we don't have a last gif in the GifsContext
    const isFirstRender = !lastRenderedGif
    // guess the height of the gif based on mobileSSRWidth
    const gifHeight = getGifHeight(gif, mobileSSRWidth)
    // calculate the percent for the server
    const percent = `${Math.min(1, targetHeight / gifHeight) * 100}%`
    // calculate the width for the client
    const widthFromPercent = (windowWidth * parseFloat(percent)) / 100
    // get the last width and height so we can transition
    const height = isFirstRender ? undefined : getGifHeight(gif, widthFromPercent)
    const width = isFirstRender ? mobileSSRWidth : widthFromPercent
    // using a percentage width and aspect ratio lets the browser know the height
    const aspectRatio = gif.images.original.width / gif.images.original.height
    return (
        <div className="relative flex justify-center">
            {isVideo ? (
                <Video
                    hideTitle
                    controls
                    style={{ aspectRatio }}
                    gif={serverGif}
                    width={width}
                    height={height}
                    hideAttribution
                    percentWidth={isFirstRender ? percent : undefined}
                />
            ) : (
                <>
                    <Gif
                        noLink
                        style={{ aspectRatio }}
                        gif={serverGif}
                        width={width}
                        height={height}
                        percentWidth={isFirstRender ? percent : undefined}
                        backgroundColor={gif.is_sticker ? undefined : 'rgba(0,0,0,0)'}
                    />
                    <GifNav gif={serverGif} />
                </>
            )}
        </div>
    )
}
export default ResponsiveGif
